import * as React from "react";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import Loading from "../../components/Loading";
import Error from "../../components/Error";
import PaymentContent from "./PaymentContent";
import Invalid from "./Invalid";
import PaymentSlider from "../../components/PaymentSlider";
import {
  GET_PAYMENT_LINK_INFO,
  GET_SUPPORTED_CURRENCIES,
} from "../../queries/payment";
import { CurrencyType, Bank, PaymentLinkReceiver } from "../../types/payment";

type PaymentLinkInfo = {
  bank: Bank | undefined;
  receiver: PaymentLinkReceiver | undefined;
  amount: string | undefined;
  receiverWalletId: number | undefined;
  paymentInfo: any | undefined;
};

const Payment = () => {
  const [invalidError, setInvalidError] = React.useState(false);
  const [currencies, setCurrencies] = React.useState<CurrencyType[]>([]);
  const [paymentLinkInfo, setPaymentLinkInfo] =
    React.useState<PaymentLinkInfo | null>(null);

  const { id: payment_string } = useParams();

  const {
    loading: paymentLinkInfoLoading,
    error: paymentLinkInfoError,
    refetch: paymentLinkInfoRefetch,
  } = useQuery(GET_PAYMENT_LINK_INFO, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",

    variables: {
      payment_string,
    },
    onCompleted: (data) => {
      const { user, bank, wallet_id, amount, paymentInfo } =
        data.getPaymentLinkInfo;

      setPaymentLinkInfo({
        bank,
        amount,
        receiver: user,
        paymentInfo,
        receiverWalletId: wallet_id,
      });
    },

    onError: (error) => {
      const inValidError = "invalid payment link";

      const formattedError = error
        .toString()
        .replace("ApolloError:", "")
        .toLowerCase();

      if (formattedError.includes(inValidError)) setInvalidError(true);
    },
  });

  const {
    loading: currenciesLoading,
    error: currenciesError,
    refetch: currenciesRefetch,
  } = useQuery(GET_SUPPORTED_CURRENCIES, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",

    onCompleted: ({ getCurrencies }) => {
      const currencies = getCurrencies.sourceCurrencies;
      setCurrencies(currencies);
    },
  });

  const handleRefetch = () => {
    paymentLinkInfoRefetch();
    currenciesRefetch();
  };

  return (
    <Box p="20px">
      {paymentLinkInfoLoading || currenciesLoading ? (
        <Loading />
      ) : (
        <>
          {paymentLinkInfoError || currenciesError ? (
            <>
              {invalidError ? (
                <Invalid />
              ) : (
                <Error onTryAgain={() => handleRefetch()} />
              )}
            </>
          ) : (
            <Box
              mx="auto"
              maxWidth={1280}
              sx={{ display: { sx: "block", lg: "flex" }, my: { lg: "78px" } }}
            >
              <PaymentContent
                bank={paymentLinkInfo?.bank}
                paymentInfo={paymentLinkInfo?.paymentInfo}
                receiver={paymentLinkInfo?.receiver}
                amount={paymentLinkInfo?.amount}
                receiverWalletId={paymentLinkInfo?.receiverWalletId}
                currencies={currencies}
              />

              <PaymentSlider />
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default Payment;
